import styled from "styled-components"

export const Container = styled.h2`
   padding-block: 2rem 1.5rem;

   font-size: clamp(1.5rem, 3vw, 1.75rem);
   line-height: 1.2;

   font-weight: 500;

   display: flex;
   align-items: center;
   gap: 0.5rem;

   &::before {
      content: "";
      width: 8px;
      aspect-ratio: 2/1;
      background-color: ${(props) => props.theme.purple};
      border-radius: 2px;
   }

   @media (min-width: 70em) {
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      gap: 2rem;
      text-align: center;

      &::before {
         width: 100%;
         aspect-ratio: unset;
         height: 2px;
      }

      &::after {
         content: "";
         background-color: ${(props) => props.theme.purple};
         width: 100%;
         border-radius: 2px;
         aspect-ratio: unset;
         height: 2px;
      }
   }
`
