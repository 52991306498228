export const links = [
    {
        path: "plataformas-automacao",
        label: "Plataforma Automação",
    },
    {
        path: "runrunit",
        label: "Runrun.it",
    },
    {
        path: "arquivos-drive",
        label: "Arquivos Drive",
    },
    // {
    //     path: "criativos",
    //     label: "Criativos",
    // },
];
