import styled from "styled-components"

export const Menu = styled.aside`
   align-self: start;
   gap: 1rem;
   margin-top: 2.25rem;
   display: none;

   button {
      outline: none;
      border: none;
      background: transparent;
      color: inherit;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      cursor: pointer;

      &:hover,
      &:focus {
         text-decoration: underline;
         text-decoration-color: ${(props) => props.theme.green};
         text-underline-offset: 3px;
      }

      &::before {
         content: "";
         background-color: ${(props) => props.theme.green};
         width: 4px;
         height: 2px;
         border-radius: 2px;
      }
   }

   @media (min-width: 1200px) {
      display: grid;
      position: sticky;
      top: 1rem;
   }
`
