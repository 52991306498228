import React from "react";
import { Container } from "./style";

const Form = ({ children, ...props }) => {
    return (
        <Container onSubmit={(e) => e.preventDefault()} {...props}>
            {children}
        </Container>
    );
};

export default Form;
