import React, { useEffect, useState } from "react"
import { MidiaContext } from "./context"
import {
   api_fetch_utm_midia_geral,
   api_post_utm_midia_google,
   api_post_utm_midia_facebook,
   api_post_utm_midia_linkedin,
   api_delete_utm_midia_google,
   api_delete_utm_midia_facebook,
   api_delete_utm_midia_linkedin,
} from "./api"

export const MidiaContextProvider = ({ children }) => {
   const [utms, setUtms] = useState([])
   const [loading, setloading] = useState(true)

   async function handleApiFetch() {
      try {
         const response = await api_fetch_utm_midia_geral()

         if (response) {
            setUtms(response)
         }
      } catch (e) {
         throw e
      } finally {
         setloading(false)
      }
   }

   useEffect(() => {
      handleApiFetch()
   }, [])

   return (
      <MidiaContext.Provider
         value={{
            utms: utms,
            setUtms,
            loading,
            actions: {
               api_post_utm_midia_google,
               api_post_utm_midia_facebook,
               api_post_utm_midia_linkedin,
               api_delete_utm_midia_google,
               api_delete_utm_midia_facebook,
               api_delete_utm_midia_linkedin,
            },
         }}
      >
         {children}
      </MidiaContext.Provider>
   )
}
