export const links = [
   {
      path: "google",
      label: "Google",
   },
   {
      path: "facebook",
      label: "Facebook",
   },
   {
      path: "linkedin",
      label: "Linkedin",
   },
]
