import React, { useEffect } from "react";
import { LoginModal } from "../../components/LoginModal";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const Login = () => {
    const { authenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated) {
            navigate("/", { replace: true });
        }
    }, [authenticated, navigate]);

    return <LoginModal />;
};
