import { Container } from "./style"
import GrupoProjeto from "./GrupoProjeto"
import NomeProjeto from "./NomeProjeto"
import SubgrupoProjeto from "./SubgrupoProjeto"
import Subtarefa from "./Subtarefa"
import Tarefa from "./Tarefa"

export function RunrunitContainer() {
   return (
      <Container>
         <Subtarefa />
         <Tarefa />
         <NomeProjeto />
         <SubgrupoProjeto />
         <GrupoProjeto />
      </Container>
   )
}
