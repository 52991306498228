import styled from "styled-components";
import { css } from "styled-components";

export const Container = styled.div`
    header {
        background-color: ${(props) => props.theme.red};
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        padding: 1rem;
        border-radius: 7px;
        color: ${(props) => props.theme.white};

        button {
            outline: none;
            border: none;
            font-size: 2rem;
            cursor: pointer;
            background-color: transparent;
            display: grid;
            place-items: center;
            color: ${(props) => props.theme.white};
            padding: 0 0.25rem;

            &:hover,
            &:focus {
                outline: 1px dotted #ccc;
                outline-offset: 2px;
            }
        }
    }

    ul {
        display: grid;
        gap: 1rem;

        max-height: 0;
        overflow: hidden;

        background-color: ${(props) => props.theme.white};

        transition: max-height 300ms linear;

        border-radius: 0 0 7px 7px;
        padding: 0 1rem;
        color: ${(props) => props.theme.black};

        li:first-child {
            padding-top: 1rem;
        }

        li:last-child {
            padding-bottom: 1rem;
        }

        li {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &::before {
                content: "";
                display: block;
                background-color: ${(props) => props.theme.red};
                width: 5px;
                height: 2px;
            }
        }
    }

    ${(props) =>
        props.active &&
        css`
            header {
                border-radius: 7px 7px 0 0;
            }

            ul {
                /* height: auto; */
                max-height: ${(props) =>
                    props.listHeight ? `${props.listHeight}px` : null};
            }
        `}
`;

export const CustomList = styled.ul``;
