import Form from "../../../../components/Form"
import CopyToClipboard from "../../../../components/CopyToClipboard"
import FieldGroup from "../../../../components/FieldGroup"
import Button from "../../../../components/Button"
import { SheetRules } from "../../../../components/SheetRules"
import { useEffect, useState } from "react"
import { useMidiaContext } from "../../../../hooks/useMidiaContext"
import { v4 as uuidv4 } from "uuid"
import { UTMMidiaCard } from "../../../../components/UTM/UTMMidiaCard"
import { ToastContainer, toast } from "react-toastify"
import { utmMediaInputValidation } from "../../../../validations/utm"
import * as S from "../generalStyles"
import * as DATA from "./data"
import * as G_DATA from "../../common/data"
import UTMList from "../../../../components/UTM/UTMList"

export const Google = () => {
   const {
      utms,
      utms: { utmMidiaGoogles },
      setUtms,
      loading,
      actions,
   } = useMidiaContext()

   const NOMEN_LOCAL_UTM_DATA = "NOMEN_LOCAL_UTM_MIDIA_GOOGLE_DATA"
   const bgColor = "#3f6bb8"
   const clientsBg = "#2a5163"

   const date = new Date().toLocaleDateString("pt-br")

   const [client, setClient] = useState("")
   const [productsList, setProductsList] = useState([])
   const [region, setRegion] = useState(G_DATA.REGION[0])
   const [isLenovoSelected, setIsLenovoSelected] = useState(false)
   const [clientFilter, setClientFilter] = useState("")

   // Blue states
   const [funil, setFunil] = useState(G_DATA.FUNIL[0])
   const [currentDate, setCurrentDate] = useState(date)
   const [produto, setProduto] = useState("")
   const [tipo, setTipo] = useState(DATA.tipo[0])
   const [utmTerm, setUtmTerm] = useState("")
   const [grupoAnuncio, setGrupoAnuncio] = useState("")
   const [anuncio, setAnuncio] = useState("")
   const [URLFinal, setURLFinal] = useState("")

   function handleRemoveSlashURL(e) {
      const lastLetter = URLFinal[URLFinal.length - 1]

      if (lastLetter === "/") {
         const newURL = URLFinal.slice(0, -1)
         setURLFinal(newURL)
      }
   }

   useEffect(() => {
      loadLocalStorageData()
   }, [])

   useEffect(() => {
      if (tipo === "search") {
         setUtmTerm("{keyword}")
      } else {
         setUtmTerm("")
      }
   }, [tipo])

   useEffect(() => {
      if (client.toLowerCase() === "lenovo") {
         setIsLenovoSelected(true)
      } else {
         setIsLenovoSelected(false)
      }

      if (client) {
         const c_product = G_DATA.DATA[client]?.produto
         // const c_region = G_DATA.DATA[client].regiao

         if (c_product) {
            setProductsList(c_product)
            setProduto(c_product[0])
         }
      } else {
         setProductsList([])
         setProduto("")
      }
   }, [client])

   function loadLocalStorageData() {
      const localStorageData = JSON.parse(localStorage.getItem(NOMEN_LOCAL_UTM_DATA))

      if (!localStorageData) return

      const { client, funil, produto, grupoAnuncio, anuncio, URLFinal } = localStorageData

      setClient(client)
      setFunil(funil)
      setProduto(produto)
      setGrupoAnuncio(grupoAnuncio)
      setAnuncio(anuncio)
      setURLFinal(URLFinal)
   }

   function saveToLocalStorage() {
      localStorage.setItem(
         NOMEN_LOCAL_UTM_DATA,
         JSON.stringify({
            client,
            funil,
            produto,
            grupoAnuncio,
            anuncio,
            URLFinal,
         })
      )
   }

   function handleSaveUtm() {
      let obj = {
         identificador: uuidv4(),
         cliente: client,
         regiao: region,
         funil: funil,
         data: date,
         produto: produto,
         tipo: tipo,
         utmterm: utmTerm,
         grupoanuncio: grupoAnuncio,
         anuncio: anuncio,
         urlraw: URLFinal,
         bNomecampanha: `bowe_${funil}_${produto}_${tipo}${region === "geral" ? "" : "_" + region}`,
         bNomegrupoanuncio: `bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}${region === "geral" ? "" : "_" + region}`,
         bNomeanuncio: "NÃO TEM NOME DE ANUNCIO",
         bUtmcontent: `bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}${region === "geral" ? "" : "_" + region}`,
         bUtmsource: "google",
         bUtmmedium: "cpc",
         bUtmterm: "{keyword}",
         bUrlparam: `${URLFinal}/?utm_source=google&utm_medium=cpc&utm_campaign=${`bowe_${funil}_${produto}_${tipo}`}&utm_content=${`${grupoAnuncio}_${anuncio}`}&utm_term={keyword}&utm_content=${`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}`}`,
      }

      if (isLenovoSelected) {
         obj = {
            ...obj,
            bNomecampanha: `[bowe][${funil}][${produto}][${tipo}]${region === "geral" ? "" : `[${region}]`}`,
            bNomegrupoanuncio: `[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]${
               region === "geral" ? "" : `[${region}]`
            }`,
            bUtmcontent: `[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]${
               region === "geral" ? "" : `[${region}]`
            }`,
            bUrlparam: `${URLFinal}/?utm_source=google&utm_medium=cpc&utm_campaign=${`[bowe][${funil}][${produto}][${tipo}`}]&utm_content=${`${grupoAnuncio}_${anuncio}`}&utm_term=${
               tipo === "search" ? "{keyword}" : utmTerm
            }&utm_content=${`[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]`}`,
         }
      }

      actions.api_post_utm_midia_google(obj)
      const newArray = [obj, ...utmMidiaGoogles]
      setUtms({ ...utms, utmMidiaGoogles: newArray })
   }

   const notifySucess = (copy) => {
      toast.success(copy, {
         position: "top-center",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: false,
         draggable: false,
         progress: undefined,
         theme: "light",
      })
   }

   async function handleDeleteEntry(id) {
      const resp = window.confirm("Deseja realmente apagar essa UTM?")

      if (!resp) return

      try {
         await actions.api_delete_utm_midia_google(id)

         const allUtmsEntries = [...utmMidiaGoogles]
         const deletedItem = allUtmsEntries.find((utmCard) => String(utmCard.identificador) === String(id))
         const index = allUtmsEntries.indexOf(deletedItem)
         if (index > -1) {
            allUtmsEntries.splice(index, 1)

            const newArray = allUtmsEntries
            setUtms({ ...utms, utmMidiaGoogles: newArray })
         }
      } catch (err) {
         throw err
      }

      notifySucess("UTM apagada!")
   }

   return (
      <>
         <S.Container>
            <SheetRules>
               <li>
                  <S.LittleBox></S.LittleBox>O que vai nas plataformas
               </li>
               <li>
                  <S.LittleBox bg={bgColor}></S.LittleBox>campo editavel para definir
               </li>
            </SheetRules>
            {/* Blue Fields */}
            <Form>
               <S.CustomUTMMidiaField bg={clientsBg}>
                  <FieldGroup
                     as="select"
                     label="Cliente"
                     value={client}
                     options={G_DATA.CLIENTS}
                     onChange={(e) => setClient(e.target.value)}
                     onBlur={saveToLocalStorage}
                  />
               </S.CustomUTMMidiaField>

               <S.CustomUTMMidiaField bg={clientsBg}>
                  <FieldGroup
                     as="select"
                     label="Região"
                     value={region}
                     options={client.toLocaleLowerCase() === "lenovo" ? G_DATA.DATA.LENOVO.regiao : G_DATA.REGION}
                     onChange={(e) => setRegion(e.target.value)}
                     // onBlur={saveToLocalStorage}
                  />
               </S.CustomUTMMidiaField>

               <S.CustomUTMMidiaField bg={bgColor}>
                  <FieldGroup
                     as="select"
                     label="Funil"
                     value={funil}
                     options={G_DATA.FUNIL}
                     onChange={(e) => setFunil(e.target.value)}
                     onBlur={saveToLocalStorage}
                  />
               </S.CustomUTMMidiaField>

               <S.CustomUTMMidiaField bg={bgColor}>
                  <FieldGroup
                     as="input"
                     label="Data"
                     value={currentDate}
                     onChange={(e) => setCurrentDate(e.target.value)}
                     onBlur={saveToLocalStorage}
                  />
               </S.CustomUTMMidiaField>

               {client === "" ? (
                  <S.CustomUTMMidiaField bg={bgColor}>
                     <FieldGroup
                        as="input"
                        label="Produto"
                        value="SELECIONE UM CLIENTE"
                        readOnly
                        onChange={(e) => setProduto(e.target.value)}
                     />
                  </S.CustomUTMMidiaField>
               ) : (
                  <S.CustomUTMMidiaField bg={bgColor}>
                     <FieldGroup
                        as="select"
                        label="Produto"
                        options={productsList}
                        value={produto}
                        onChange={(e) => setProduto(e.target.value)}
                        onBlur={saveToLocalStorage}
                     />
                  </S.CustomUTMMidiaField>
               )}

               <S.CustomUTMMidiaField bg={bgColor}>
                  <FieldGroup
                     as="select"
                     label="Tipo"
                     value={tipo}
                     options={DATA.tipo}
                     onChange={(e) => setTipo(e.target.value)}
                     onBlur={saveToLocalStorage}
                  />
               </S.CustomUTMMidiaField>

               <S.CustomUTMMidiaField bg={bgColor}>
                  <FieldGroup
                     as="input"
                     label="UTM Term"
                     value={utmTerm}
                     onChange={(e) => setUtmTerm(e.target.value)}
                     onBlur={saveToLocalStorage}
                     onInput={utmMediaInputValidation}
                  />
               </S.CustomUTMMidiaField>

               <S.CustomUTMMidiaField bg={bgColor}>
                  <FieldGroup
                     as="input"
                     label="Grupo de anuncio (Google)"
                     value={grupoAnuncio}
                     onChange={(e) => setGrupoAnuncio(e.target.value)}
                     onBlur={saveToLocalStorage}
                     onInput={utmMediaInputValidation}
                  />
               </S.CustomUTMMidiaField>

               <S.CustomUTMMidiaField bg={bgColor}>
                  <FieldGroup
                     as="input"
                     label="anúncio"
                     value={anuncio}
                     onChange={(e) => setAnuncio(e.target.value)}
                     onBlur={saveToLocalStorage}
                     onInput={utmMediaInputValidation}
                  />
               </S.CustomUTMMidiaField>

               <S.CustomUTMMidiaField bg={bgColor}>
                  <FieldGroup
                     as="input"
                     label="URL Final"
                     value={URLFinal}
                     onChange={(e) => setURLFinal(e.target.value)}
                     onBlur={() => {
                        handleRemoveSlashURL()
                        saveToLocalStorage()
                     }}
                     onInput={utmMediaInputValidation}
                  />
               </S.CustomUTMMidiaField>
            </Form>

            <S.CustomTitleDivisor>
               <h3>Campos que vão na plataforma</h3>
            </S.CustomTitleDivisor>

            {/* Black fields */}
            {client.toLowerCase() === "lenovo" ? (
               <div>
                  <h1 style={{ marginBlock: "3rem 2rem", color: "red" }}>
                     <img src="/images/lenovo.png" alt="Lenovo" style={{ maxWidth: "10rem", marginInline: "auto" }} />
                  </h1>
                  <Form style={{ marginTop: 0 }}>
                     <S.CustomUTMMidiaField>
                        <S.ButtonCopyTextWrapper>
                           <CopyToClipboard
                              copyText={`[bowe][${funil}][${produto}][${tipo}]${
                                 region === "geral" ? "" : `[${region}]`
                              }`}
                           />
                        </S.ButtonCopyTextWrapper>

                        <FieldGroup
                           as="input"
                           label="nome da campanha e utm_campaign"
                           value={`[bowe][${funil}][${produto}][${tipo}]${region === "geral" ? "" : `[${region}]`}`}
                           readOnly
                        />
                     </S.CustomUTMMidiaField>

                     <S.CustomUTMMidiaField>
                        <S.ButtonCopyTextWrapper>
                           <CopyToClipboard
                              copyText={`[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]${
                                 region === "geral" ? "" : `[${region}]`
                              }`}
                           />
                        </S.ButtonCopyTextWrapper>

                        <FieldGroup
                           as="input"
                           label="nome do grupo de anúncio"
                           value={`[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]${
                              region === "geral" ? "" : `[${region}]`
                           }`}
                           readOnly
                        />
                     </S.CustomUTMMidiaField>

                     <S.CustomUTMMidiaField>
                        {tipo === "search" ? (
                           <>
                              <S.ButtonCopyTextWrapper>
                                 <CopyToClipboard copyText={`NÃO TEM NOME DE ANUNCIO`} />
                              </S.ButtonCopyTextWrapper>

                              <FieldGroup
                                 readOnly
                                 as="input"
                                 label="nome do anúncio (tipo search não se aplica)"
                                 value={`NÃO TEM NOME DE ANUNCIO`}
                              />
                           </>
                        ) : (
                           <>
                              <S.ButtonCopyTextWrapper>
                                 <CopyToClipboard
                                    copyText={`[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]${
                                       region === "geral" ? "" : `[${region}]`
                                    }[${anuncio}]`}
                                 />
                              </S.ButtonCopyTextWrapper>

                              <FieldGroup
                                 readOnly
                                 as="input"
                                 label="nome do anúncio (tipo search não se aplica)"
                                 value={`[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]${
                                    region === "geral" ? "" : `[${region}]`
                                 }[${anuncio}]`}
                              />
                           </>
                        )}
                     </S.CustomUTMMidiaField>

                     <S.CustomUTMMidiaField>
                        <S.ButtonCopyTextWrapper>
                           <CopyToClipboard
                              copyText={`[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]${
                                 region === "geral" ? "" : `[${region}]`
                              }`}
                           />
                        </S.ButtonCopyTextWrapper>

                        <FieldGroup
                           readOnly
                           as="input"
                           label="utm content"
                           value={`[bowe][${funil}][${produto}][${tipo}][${grupoAnuncio}]${
                              region === "geral" ? "" : `[${region}]`
                           }`}
                        />
                     </S.CustomUTMMidiaField>

                     <S.CustomUTMMidiaField>
                        <S.ButtonCopyTextWrapper>
                           <CopyToClipboard copyText="google" />
                        </S.ButtonCopyTextWrapper>

                        <FieldGroup readOnly as="input" label="utm source" value="google" />
                     </S.CustomUTMMidiaField>

                     <S.CustomUTMMidiaField>
                        <S.ButtonCopyTextWrapper>
                           <CopyToClipboard copyText="cpc" />
                        </S.ButtonCopyTextWrapper>

                        <FieldGroup readOnly as="input" label="utm medium" value="cpc" />
                     </S.CustomUTMMidiaField>

                     <S.CustomUTMMidiaField>
                        <S.ButtonCopyTextWrapper>
                           <CopyToClipboard copyText={"{keyword}"} />
                        </S.ButtonCopyTextWrapper>

                        <FieldGroup readOnly as="input" label="utm term" value="{keyword}" />
                     </S.CustomUTMMidiaField>

                     <S.CustomUTMMidiaField span="true" copy="true">
                        <FieldGroup
                           readOnly
                           as="input"
                           label="url parametrizada"
                           value={`${URLFinal}/?utm_source=google&utm_medium=cpc&utm_campaign=${`bowe_${funil}_${produto}_${tipo}`}&utm_term={keyword}&utm_content=${`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}`}`}
                        />
                        <CopyToClipboard
                           copyText={`${URLFinal}/?utm_source=google&utm_medium=cpc&utm_campaign=${`bowe_${funil}_${produto}_${tipo}`}&utm_term={keyword}&utm_content=${`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}`}`}
                        />
                     </S.CustomUTMMidiaField>
                  </Form>
               </div>
            ) : (
               <Form>
                  <S.CustomUTMMidiaField>
                     <S.ButtonCopyTextWrapper>
                        <CopyToClipboard
                           copyText={`bowe_${funil}_${produto}_${tipo}${region === "geral" ? "" : "_" + region}`}
                        />
                     </S.ButtonCopyTextWrapper>

                     <FieldGroup
                        as="input"
                        label="nome da campanha e utm_campaign"
                        value={`bowe_${funil}_${produto}_${tipo}${region === "geral" ? "" : "_" + region}`}
                        readOnly
                     />
                  </S.CustomUTMMidiaField>

                  <S.CustomUTMMidiaField>
                     <S.ButtonCopyTextWrapper>
                        <CopyToClipboard
                           copyText={`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}${
                              region === "geral" ? "" : "_" + region
                           }`}
                        />
                     </S.ButtonCopyTextWrapper>

                     <FieldGroup
                        as="input"
                        label="nome do grupo de anúncio"
                        value={`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}${
                           region === "geral" ? "" : "_" + region
                        }`}
                        readOnly
                     />
                  </S.CustomUTMMidiaField>

                  <S.CustomUTMMidiaField>
                     {tipo === "search" ? (
                        <>
                           <S.ButtonCopyTextWrapper>
                              <CopyToClipboard copyText={`NÃO TEM NOME DE ANUNCIO`} />
                           </S.ButtonCopyTextWrapper>

                           <FieldGroup
                              readOnly
                              as="input"
                              label="nome do anúncio (tipo search não se aplica)"
                              value={`NÃO TEM NOME DE ANUNCIO`}
                           />
                        </>
                     ) : (
                        <>
                           <S.ButtonCopyTextWrapper>
                              <CopyToClipboard
                                 copyText={`bowe_${funil}_${produto}_${tipo}${grupoAnuncio ? `_${grupoAnuncio}` : ""}${
                                    region === "geral" ? "" : "_" + region
                                 }${anuncio ? `_${anuncio}` : ""}`}
                              />
                           </S.ButtonCopyTextWrapper>

                           <FieldGroup
                              readOnly
                              as="input"
                              label="nome do anúncio (tipo search não se aplica)"
                              value={`bowe_${funil}_${produto}_${tipo}${grupoAnuncio ? `_${grupoAnuncio}` : ""}${
                                 region === "geral" ? "" : "_" + region
                              }${anuncio ? `_${anuncio}` : ""}`}
                           />
                        </>
                     )}
                  </S.CustomUTMMidiaField>

                  <S.CustomUTMMidiaField>
                     <S.ButtonCopyTextWrapper>
                        <CopyToClipboard
                           copyText={`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}${
                              region === "geral" ? "" : "_" + region
                           }`}
                        />
                     </S.ButtonCopyTextWrapper>

                     <FieldGroup
                        readOnly
                        as="input"
                        label="utm content"
                        value={`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}${
                           region === "geral" ? "" : "_" + region
                        }`}
                     />
                  </S.CustomUTMMidiaField>

                  <S.CustomUTMMidiaField>
                     <S.ButtonCopyTextWrapper>
                        <CopyToClipboard copyText="google" />
                     </S.ButtonCopyTextWrapper>

                     <FieldGroup readOnly as="input" label="utm source" value="google" />
                  </S.CustomUTMMidiaField>

                  <S.CustomUTMMidiaField>
                     <S.ButtonCopyTextWrapper>
                        <CopyToClipboard copyText="cpc" />
                     </S.ButtonCopyTextWrapper>

                     <FieldGroup readOnly as="input" label="utm medium" value="cpc" />
                  </S.CustomUTMMidiaField>

                  <S.CustomUTMMidiaField>
                     <S.ButtonCopyTextWrapper>
                        <CopyToClipboard copyText={"{keyword}"} />
                     </S.ButtonCopyTextWrapper>

                     <FieldGroup readOnly as="input" label="utm term" value="{keyword}" />
                  </S.CustomUTMMidiaField>

                  <S.CustomUTMMidiaField span="true" copy="true">
                     <FieldGroup
                        readOnly
                        as="input"
                        label="url parametrizada"
                        value={`${URLFinal}/?utm_source=google&utm_medium=cpc&utm_campaign=${`bowe_${funil}_${produto}_${tipo}`}&utm_term={keyword}&utm_content=${`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}`}`}
                     />
                     <CopyToClipboard
                        copyText={`${URLFinal}/?utm_source=google&utm_medium=cpc&utm_campaign=${`bowe_${funil}_${produto}_${tipo}`}&utm_term={keyword}&utm_content=${`bowe_${funil}_${produto}_${tipo}_${grupoAnuncio}`}`}
                     />
                  </S.CustomUTMMidiaField>
               </Form>
            )}

            <Button onClick={handleSaveUtm}>Guardar UTM</Button>

            {/* UTM LIST */}
            {loading && <h2 style={{ textAlign: "center", marginBlock: "2rem" }}>Buscando UTMs...</h2>}

            {!loading && (
               <UTMList value={clientFilter} onChange={(e) => setClientFilter(e.target.value)}>
                  {utmMidiaGoogles
                     .filter((utm) => utm.cliente.toLowerCase().includes(clientFilter.toLowerCase()))
                     .map((utm) => (
                        <div key={utm.identificador}>
                           <UTMMidiaCard
                              handleDeleteEntry={() => handleDeleteEntry(utm.identificador)}
                              key={utm.identificador}
                              client={utm.cliente}
                              date={utm.data}
                              url={utm.urlraw}
                              urlParam={utm.bUrlparam}
                              identificador={utm.identificador}
                           >
                              <p>
                                 <strong>Região: </strong>
                                 {utm.regiao}
                              </p>
                              <p>
                                 <strong>Funil: </strong>
                                 {utm.funil}
                              </p>
                              <p>
                                 <strong>Produto: </strong>
                                 {utm.produto}
                              </p>
                              <p>
                                 <strong>Tipo: </strong>
                                 {utm.tipo}
                              </p>
                              <p>
                                 <strong>utm_term: </strong>
                                 {utm.utmterm}
                              </p>
                              <p>
                                 <strong>Grupo de anúncio: </strong>
                                 {utm.grupoanuncio}
                              </p>
                              <p>
                                 <strong>Anúncio: </strong>
                                 {utm.anuncio}
                              </p>
                              <p>
                                 <strong>Url: </strong>
                                 {utm.urlraw}
                              </p>
                              <p>
                                 <strong>Nome da campanha: </strong>
                                 {utm.bNomecampanha}
                              </p>
                              <p>
                                 <strong>Nome do grupo de anúncio: </strong>
                                 {utm.bNomegrupoanuncio}
                              </p>
                              <p>
                                 <strong>Nome do anúncio: </strong>
                                 {utm.bNomeanuncio}
                              </p>
                              <p>
                                 <strong>utm_content: </strong>
                                 {utm.bUtmcontent}
                              </p>
                              <p>
                                 <strong>utm_source: </strong>
                                 {utm.bUtmsource}
                              </p>
                              <p>
                                 <strong>utm_medium: </strong>
                                 {utm.bUtmmedium}
                              </p>
                              <p>
                                 <strong>utm_term: </strong>
                                 {utm.bUtmterm}
                              </p>
                           </UTMMidiaCard>
                        </div>
                     ))}
               </UTMList>
            )}
         </S.Container>

         <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
      </>
   )
}
