import React, { useEffect, useRef, useState } from "react";
import { Container } from "./style";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

export const SheetRules = ({ children, ...props }) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0);

    function handleClick() {
        setOpen((prev) => !prev);
    }

    const listRef = useRef();

    useEffect(() => {
        if (listRef.current) {
            setHeight(listRef.current.scrollHeight);
        }
    }, [listRef]);

    return (
        <Container listHeight={height} {...props} active={open}>
            <header>
                <h3>Regras da planilha</h3>
                <button
                    onClick={handleClick}
                    aria-label="abre acordeão de regras"
                >
                    {open ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
                </button>
            </header>
            <ul ref={listRef}>{children}</ul>
        </Container>
    );
};
