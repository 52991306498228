import { useState } from "react"
import { inputValidity } from "../../../helpers/inputValidity"
import { useData } from "../../../../../hooks/useData"
import FieldGroup from "../../../../../components/FieldGroup"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"

const NomeProjeto = () => {
   const { data } = useData()
   const projectTypes = ["Flow", "Sprint"]

   const [id, setId] = useState("")
   const [cliente, setCliente] = useState(data.geral[0].clientes[0])
   const [type, setType] = useState(projectTypes[0])
   const [date, setDate] = useState("")
   const [grupoDoEntregavel, setGrupoDoEntregavel] = useState("")

   return (
      <section id="nome-projeto">
         <SectionTitle>Nome do Projeto</SectionTitle>

         <Form>
            <FieldGroup
               as="input"
               label="ID *"
               value={id}
               onChange={({ target }) => setId(target.value)}
               onBlur={inputValidity}
            />

            <FieldGroup
               as="select"
               label="Cliente *"
               options={data.geral[0].clientes}
               onChange={({ target }) => setCliente(target.value)}
               value={cliente}
            />

            <FieldGroup
               as="select"
               label="Flow/Sprint *"
               options={projectTypes}
               onChange={({ target }) => setType(target.value)}
               value={type}
            />

            <FieldGroup
               as="input"
               label="mês/mês/mês - ano *"
               value={date}
               onChange={({ target }) => setDate(target.value)}
               onBlur={inputValidity}
            />

            <FieldGroup
               as="input"
               label="Grupo do entregável *"
               value={grupoDoEntregavel}
               onChange={({ target }) => setGrupoDoEntregavel(target.value)}
               onBlur={inputValidity}
            />
         </Form>

         <ResultLabel>{`${id ? `${id} |` : ""} [${cliente}] | ${type} | ${date} | ${grupoDoEntregavel}`}</ResultLabel>
      </section>
   )
}

export default NomeProjeto
