import { useEffect, useState } from "react"
import { createContext } from "react"
import { api_fetch } from "../services/api"

export const DataContext = createContext()
DataContext.displayName = "Data Context"

export const DataContextProvider = ({ children }) => {
   const [data, setData] = useState({})
   const [loading, setLoading] = useState(true)

   useEffect(() => {
      handleLoadData()
   }, [])

   async function handleLoadData() {
      setLoading(true)

      const result = await api_fetch()

      if (result) {
         setData(result)
      }

      setLoading(false)
   }

   return <DataContext.Provider value={{ data, loading }}>{children}</DataContext.Provider>
}
