import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({
    authenticated,
    children,
    redirectPath = "/login",
}) => {
    if (!authenticated) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};
