import styled from "styled-components";

export const Container = styled.div`
    border-left: 1px solid ${(props) => props.theme.red};
    border-bottom: 1px solid ${(props) => props.theme.red};

    border-bottom-left-radius: 5px;
    padding: 0.5rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
    max-width: max-content;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
        flex-shrink: 0;
        color: ${(props) => props.theme.white};
    }

    p {
        font-weight: 600;
        color: ${(props) => props.theme.white};

        span {
            font-weight: 400;
        }
    }
`;
