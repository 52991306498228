import React from "react"
import Header from "../Header"
import { Container } from "./style"

export const Layout = ({ children }) => {
   return (
      <Container>
         <Header />
         <main>{children}</main>
      </Container>
   )
}
