import React, { useState } from "react"
import { Container } from "./style"
import { MdContentCopy, MdCheck } from "react-icons/md"

const CopyToClipboard = ({ copyText, disabled, ...props }) => {
   const [coppied, setCoppied] = useState(false)

   const handleCopy = (value) => {
      navigator.clipboard.writeText(value)

      setCoppied((prev) => !prev)

      setTimeout(() => {
         setCoppied((prev) => !prev)
      }, 1500)
   }

   return (
      <Container disabled={disabled} {...props} onClick={() => handleCopy(copyText)}>
         {coppied ? <MdCheck /> : <MdContentCopy />}
      </Container>
   )
}

export default CopyToClipboard
