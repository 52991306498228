import styled from "styled-components"

export const Container = styled.div`
   display: grid;
   gap: 1rem;
   margin-top: 4rem;
`

export const FilterList = styled.div`
   padding-top: 4rem;
   position: relative;
   display: flex;
   align-items: center;
   gap: 1rem;
   font-size: 1.25rem;
   font-weight: 700;

   input,
   select {
      font-size: 1rem;
      border-radius: 6px;
      outline: none;
      border: none;
      padding: 0.5rem;
   }

   &::before {
      content: "";
      background-color: ${(props) => props.theme.white};
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
   }
`
