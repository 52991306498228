import styled from "styled-components"

export const Container = styled.button`
   background-color: ${(props) => props.theme.purple};
   color: ${(props) => props.theme.white};
   padding: 0.85rem 1rem;
   margin-top: 1rem;
   border-radius: 10px;
   font-weight: 700;
   font-size: 1.125rem;
   outline: none;
   border: none;
   cursor: pointer;
   width: 100%;

   &:hover,
   &:focus {
      filter: brightness(0.87);
      transition: all 200ms ease;
   }
`
