import * as S from "./styles"

export const Menu = ({ menus, display }) => {
   function handleHref(id) {
      if (document) {
         const el = document.getElementById(id)

         if (el) {
            el.scrollIntoView()
         }
      }
   }

   return (
      <S.Menu>
         <h3>Menu</h3>
         {menus[display].map((menu) => (
            <button onClick={() => handleHref(menu.id)} href={`#${menu.id}`} key={menu.id}>
               {menu.title}
            </button>
         ))}
      </S.Menu>
   )
}
