export const tipo = [
   "estatico",
   "animado",
   "carrossel",
   "storie",
   "leadgen",
   "txads",
   "search",
   "display",
   "pmax",
   "discovery",
   "docads",
]
