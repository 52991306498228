import styled from "styled-components"
import { DefaultContainer } from "../../components/DefaultContainer"

export const Container = styled(DefaultContainer)`
   padding-block: 4rem;

   form {
      margin-top: 4rem;
   }
`

export const ResultBox = styled.div`
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
   margin-block: 3rem 2rem;
   background-color: ${({ theme }) => theme.white};
   color: ${({ theme }) => theme.black};

   display: flex;
   justify-content: space-between;

   border-radius: 6px;
   min-height: 3rem;
   position: relative;
   overflow: hidden;

   p,
   h6 {
      word-wrap: break-word;
      word-break: break-all;
      padding: 0.5rem 1em;

      font-weight: 700;
      font-size: 1rem;
      display: grid;
      place-items: center;
   }
`
