import React from "react"
import * as S from "./style"
import { PersonDisplayCard } from "../PersonDisplayCard"
import { Link } from "react-router-dom"

const Header = () => {
   return (
      <S.Wrapper>
         <S.Container as="header">
            <img src="/images/bowe-white.png" alt="Bowe B2B" />

            <S.Navigation>
               <Link to="/">Nomenclaturas</Link>
               <Link to="/utm-copy">UTM Copy</Link>
               <Link to="/utm-midia">UTM Mídia</Link>
            </S.Navigation>

            <PersonDisplayCard />
         </S.Container>
      </S.Wrapper>
   )
}

export default Header
