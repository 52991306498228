import { useState } from "react"
import { inputValidity } from "../../../helpers/inputValidity"
import { months_reduced, years_reduced } from "../../../../../common/data"
import { ValidateErrorsBox } from "../../../../../components/ValidateErrorsBox"
import { useValidateLetters } from "../../../../../hooks/useValidateLetters"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"

const currentMonth = new Date().getMonth()

const TituloEmail = ({ data }) => {
   const dataToDisplay = {
      _bowe: "bw",
      _etapaFunil: data.plataformaAutomacao.etapaFunilPlataformas,
   }

   const { errorsList, validateLetters, blockSpecialChars } = useValidateLetters()

   const [bowe, setBowe] = useState(dataToDisplay._bowe)
   const [etapaFunil, setEtapaFunil] = useState(dataToDisplay._etapaFunil[0])
   const [tipoEmail, setTipoEmail] = useState(data.plataformaAutomacao.tiposDeEmail[0])
   const [versionamento, setVersionamento] = useState("")
   const [nomeProduto, setNomeProduto] = useState("")
   const [nomeMaterial, setNomeMaterial] = useState("")
   const [mes, setMes] = useState(months_reduced[currentMonth])
   const [ano, setAno] = useState(years_reduced[1])

   const [isOtimz, setIsOtimz] = useState("")
   const [versaoOtmiz, setVersaoOtmiz] = useState("")
   const [mesOtimz, setMesOtimz] = useState(months_reduced[currentMonth])
   const [anoOtimz, setAnoOtimz] = useState(years_reduced[1])

   const [disparo, setDisparo] = useState("")

   return (
      <section id="titulo-email">
         <SectionTitle>Título de e-mail para as plataformas de automação</SectionTitle>
         {errorsList.length > 0 && <ValidateErrorsBox list={errorsList} />}

         <Form>
            <FieldGroup
               as="input"
               label="bw (bowe) *"
               onBlur={inputValidity}
               placeholder="bw(bowe)"
               onChange={({ target }) => setBowe(target.value)}
               value={bowe}
            />

            <FieldGroup
               as="select"
               label="Etapa do Funil *"
               onChange={({ target }) => setEtapaFunil(target.value)}
               value={etapaFunil}
               options={dataToDisplay._etapaFunil}
               emptyOption="N/A"
            />

            <FieldGroup
               as="select"
               label="Tipo de email *"
               onChange={({ target }) => setTipoEmail(target.value)}
               value={tipoEmail}
               options={data.plataformaAutomacao.tiposDeEmail}
            />

            <FieldGroup
               as="input"
               label="Versionamento *"
               placeholder="Nº do email (se necessário)"
               value={versionamento}
               onChange={({ target }) => setVersionamento(target.value)}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => validateLetters(e, setVersionamento, "Versionamento *")}
            />

            <FieldGroup
               as="input"
               label="Nome do Produto *"
               onChange={({ target }) => {
                  setNomeProduto(target.value)
               }}
               value={nomeProduto}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => validateLetters(e, setNomeProduto, "Nome do Produto *")}
            />

            <FieldGroup
               as="input"
               label="Nome do material *"
               value={nomeMaterial}
               onChange={({ target }) => setNomeMaterial(target.value)}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => validateLetters(e, setNomeMaterial, "Nome do material *")}
            />

            <FieldGroup
               as="select"
               label="Mês *"
               onChange={({ target }) => setMes(target.value)}
               value={mes}
               options={months_reduced}
            />

            <FieldGroup
               as="select"
               label="Ano *"
               onChange={({ target }) => setAno(target.value)}
               value={ano}
               options={years_reduced}
            />

            <FieldGroup
               as="select"
               label="Otimização?"
               onChange={({ target }) => setIsOtimz(target.value)}
               value={isOtimz}
               options={["Sim"]}
               emptyOption={"Não"}
               span={true}
            />

            {isOtimz === "Sim" && (
               <>
                  <FieldGroup
                     as="input"
                     label="Versão da otimização *"
                     onChange={({ target }) => setVersaoOtmiz(target.value)}
                     value={versaoOtmiz}
                  />

                  <FieldGroup
                     as="select"
                     label="Mês da otimização *"
                     onChange={({ target }) => setMesOtimz(target.value)}
                     value={mesOtimz}
                     options={months_reduced}
                  />

                  <FieldGroup
                     as="select"
                     label="Ano da otimização *"
                     onChange={({ target }) => setAnoOtimz(target.value)}
                     value={anoOtimz}
                     options={years_reduced}
                  />
               </>
            )}

            <FieldGroup
               as="input"
               label="Disparo"
               placeholder="* Somente quando necessário"
               value={disparo}
               onChange={({ target }) => setDisparo(target.value)}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => validateLetters(e, setDisparo, "Disparo")}
               span={true}
            />
         </Form>

         <ResultLabel disabled={errorsList.length > 0}>
            {`${bowe}${etapaFunil ? `_${etapaFunil}` : ""}_${tipoEmail}${versionamento}_${
               nomeProduto === "N/A" ? "" : `${nomeProduto}_`
            }${nomeMaterial}_${mes}_${ano}${isOtimz === "Sim" ? `_otmz${versaoOtmiz}_${mesOtimz}_${anoOtimz}` : ""}${
               disparo ? `_${disparo}` : ""
            }`}
         </ResultLabel>
      </section>
   )
}

export default TituloEmail
