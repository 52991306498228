import { useState } from "react"
import { toLowerCaseBlur } from "../../../helpers/functions"
import { useValidateLetters } from "../../../../../hooks/useValidateLetters"
import { ValidateErrorsBox } from "../../../../../components/ValidateErrorsBox"
import FieldGroup from "../../../../../components/FieldGroup"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"

const FluxosAutomacao = ({ data }) => {
   const dataToDisplay = {
      _bowe: "bw",
      _etapaFunil: data.plataformaAutomacao.etapaFunilPlataformas,
      _nomeProduto: data.geral[0].produtos,
      _tipoFluxo: data.plataformaAutomacao.tipoDeFluxo,
      _tipoMaterial: data.plataformaAutomacao.tipoDoMaterial,
      _nomeFluxo: "",
      _slugTp: "",
   }

   const { errorsList, validateLetters, blockSpecialChars, specialSlugValidation } = useValidateLetters()

   const [bowe, setBowe] = useState(dataToDisplay._bowe)
   const [etapaFunil, setEtapaFunil] = useState("")
   const [nomeProduto, setNomeProduto] = useState("")
   const [tipoFluxo, setTipoFluxo] = useState(dataToDisplay._tipoFluxo[0])
   const [tipoMaterial, setTipoMaterial] = useState(dataToDisplay._tipoMaterial[0])
   const [nomeFluxo, setNomeFluxo] = useState(dataToDisplay._nomeFluxo)
   const [slugTp, setSlugTp] = useState(dataToDisplay._slugTp)

   return (
      <section id="fluxos-automacao">
         <SectionTitle>Nomenclatura fluxos de automação</SectionTitle>
         {errorsList.length > 0 && <ValidateErrorsBox list={errorsList} />}

         <Form>
            <FieldGroup
               as="input"
               label="bw (bowe) *"
               onChange={({ target }) => setBowe(target.value)}
               value={bowe}
               onBlur={(e) => toLowerCaseBlur(bowe, setBowe)}
            />

            <FieldGroup
               as="select"
               label="Etapa do Funil *"
               onChange={({ target }) => setEtapaFunil(target.value)}
               value={etapaFunil}
               options={dataToDisplay._etapaFunil}
               emptyOption="N/A"
            />

            <FieldGroup
               as="input"
               label="Nome do Produto *"
               onChange={({ target }) => setNomeProduto(target.value)}
               value={nomeProduto}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => validateLetters(e, setNomeProduto, "Nome do Produto *")}
            />

            <FieldGroup
               as="select"
               label="Tipo de Fluxo *"
               onChange={({ target }) => setTipoFluxo(target.value)}
               value={tipoFluxo}
               options={dataToDisplay._tipoFluxo}
            />

            <FieldGroup
               as="select"
               label="Tipo do Material"
               onChange={({ target }) => setTipoMaterial(target.value)}
               value={tipoMaterial}
               options={dataToDisplay._tipoMaterial}
               emptyOption={"N/A"}
            />

            <FieldGroup
               as="input"
               label="Nome do Fluxo *"
               onChange={({ target }) => setNomeFluxo(target.value)}
               value={nomeFluxo}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => {
                  validateLetters(e, setNomeFluxo, "Nome do Fluxo *")
               }}
            />

            <FieldGroup
               as="input"
               label="Slug TP *"
               onChange={({ target }) => setSlugTp(target.value)}
               value={slugTp}
               onKeyDown={(e) => {
                  specialSlugValidation(e)
               }}
               onBlur={(e) => {
                  validateLetters(e, setSlugTp, "Slug TP *", true)
               }}
            />
         </Form>

         <ResultLabel disabled={errorsList.length > 0}>
            {`${bowe}_${etapaFunil ? `${etapaFunil}_` : ""}${nomeProduto}_${tipoFluxo}_${
               tipoMaterial === "N/A" ? "" : `${tipoMaterial}_`
            }${nomeFluxo}_${slugTp}`}
         </ResultLabel>
      </section>
   )
}

export default FluxosAutomacao
