import React from "react";
import { Container } from "./style";
import { BiTargetLock } from "react-icons/bi";

const ObjectiveBox = ({ children, ...props }) => {
    return (
        <Container {...props}>
            <BiTargetLock />
            <p>
                Objetivo do gerador: <span>{children}</span>
            </p>
        </Container>
    );
};

export default ObjectiveBox;
