import React, { useEffect, useState } from "react"
import { ButtonsWrapper, Container } from "./style"
import { Layout } from "../../components/Layout"
import { ButtonChangeView } from "../../components/ButtonChangeView"
import { Loading } from "../../components/Loading"
import { Facebook, Google, Linkedin } from "./containers"
import { useData } from "../../hooks/useData"
import { links } from "./common/links"
import { MidiaContextProvider } from "../../contexts/MidiaContext"
import { PageTitle } from "../../components/PageTitle"

export const UTMMidia = () => {
   const DISPLAY_SETTING_NAME = "NOMEN_CURRENT_DISPLAY_MID"

   const [display, setDisplay] = useState("google")
   const { loading } = useData()

   function handleClick(value) {
      setDisplay(value)
      localStorage.setItem(DISPLAY_SETTING_NAME, JSON.stringify(value))
   }

   useEffect(() => {
      const savedDisplaySetting = JSON.parse(localStorage.getItem(DISPLAY_SETTING_NAME))

      if (savedDisplaySetting) {
         setDisplay(savedDisplaySetting)
      }
   }, [])

   const NowDisplaying = () => {
      switch (display) {
         case "google":
            return <Google />
         case "facebook":
            return <Facebook />
         case "linkedin":
            return <Linkedin />

         default:
            return null
      }
   }

   if (loading) {
      return <Loading />
   }

   return (
      <Layout>
         <MidiaContextProvider>
            <Container className="fade">
               <PageTitle>Gerador UTM Mídia</PageTitle>
               <ButtonsWrapper>
                  {links.map((link) => (
                     <ButtonChangeView
                        active={display === link.path ? true : false}
                        key={link.label}
                        onClick={(e) => handleClick(link.path)}
                     >
                        {link.label}
                     </ButtonChangeView>
                  ))}
               </ButtonsWrapper>

               <NowDisplaying />
            </Container>
         </MidiaContextProvider>
      </Layout>
   )
}
