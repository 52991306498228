import React from "react"
import * as S from "./styles"

const UTMList = ({ children, value, onChange, ...props }) => {
   return (
      <S.Container>
         <S.FilterList>
            Busque por cliente:
            <input type="text" value={value} onChange={onChange} />
         </S.FilterList>
         {children}
      </S.Container>
   )
}

export default UTMList
