import { useState } from "react"
import { inputValidity } from "../../../helpers/inputValidity"
import { useData } from "../../../../../hooks/useData"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"

const MR = {
   tamanhos: ["P", "M", "G"],
   tipos: ["E-book", "Infográfico", "OnePage", "Outros", "Planilha", "Quiz", "Slide-share", "Webinar", "Whitepaper"],
   etapa: ["ToFu", "BoFu", "MoFu"],
}

const Tarefa = () => {
   const { data } = useData()

   const [cliente, setCliente] = useState(data.geral[0].clientes[0])
   const [entregavel, setEntregavel] = useState(data.entregaveis.tarefaMae[0])
   const [nomeMaterial, setNomeMaterial] = useState("")

   const [MRtamanho, setMRtamanho] = useState(MR.tamanhos[0])
   const [MRtipo, setMRtipo] = useState(MR.tipos[0])
   const [MRetapa, setMRetapa] = useState(MR.etapa[0])

   return (
      <section id="nome-tarefa">
         <SectionTitle>Nome da Tarefa</SectionTitle>

         <Form>
            <FieldGroup
               as="select"
               label="Cliente *"
               options={data.geral[0].clientes}
               onChange={({ target }) => setCliente(target.value)}
               value={cliente}
            />

            <FieldGroup
               as="select"
               label="Entregável *"
               options={data.entregaveis.tarefaMae}
               onChange={({ target }) => setEntregavel(target.value)}
               value={entregavel}
            />

            {/* MR */}
            {entregavel === "MR" && (
               <>
                  <FieldGroup
                     as="select"
                     label="Tamanho do MR *"
                     options={MR.tamanhos}
                     value={MRtamanho}
                     onChange={({ target }) => setMRtamanho(target.value)}
                  />
                  <FieldGroup
                     as="select"
                     label="Tipo do MR *"
                     options={MR.tipos}
                     value={MRtipo}
                     onChange={({ target }) => setMRtipo(target.value)}
                  />
                  <FieldGroup
                     as="select"
                     label="Etapa do funil *"
                     options={MR.etapa}
                     value={MRetapa}
                     onChange={({ target }) => setMRetapa(target.value)}
                     emptyOption={"N/A"}
                  />
               </>
            )}

            <FieldGroup
               as="input"
               label="Nome do Material *"
               value={nomeMaterial}
               onChange={({ target }) => setNomeMaterial(target.value)}
               onBlur={inputValidity}
            />
         </Form>

         <ResultLabel>
            {entregavel !== "MR"
               ? `[${cliente}] | [${entregavel}] | ${nomeMaterial}`
               : `[${cliente}] | [MR ${MRtamanho} ${MRtipo}] | ${MRetapa ? `${MRetapa} -` : ""} ${nomeMaterial}`}
         </ResultLabel>
      </section>
   )
}

export default Tarefa
