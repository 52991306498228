import React, { useEffect, useRef, useState } from "react"
import { Container } from "./styles"
import { HiOutlineArrowCircleDown } from "react-icons/hi"

const UtmCard = ({ utm, handleDeleteEntry }) => {
   const [isOpen, setIsOpen] = useState(false)
   const [height, setHeight] = useState(0)

   const handleOpenner = () => {
      setIsOpen((prev) => !prev)
   }

   const mainRef = useRef()

   useEffect(() => {
      if (mainRef.current) {
         setHeight(mainRef.current.scrollHeight)
      }
   }, [mainRef])

   return (
      <Container listHeight={height} active={isOpen}>
         <header>
            <div>
               <h3>{utm.client}</h3>
               <div>
                  <p>{utm.date}</p>
                  <button onClick={handleOpenner}>
                     <HiOutlineArrowCircleDown />
                  </button>
               </div>
            </div>
            <p>
               <strong>URL:</strong> {utm.url}
            </p>
         </header>
         <main ref={mainRef}>
            <h3 className="url_param">{utm.urlParam}</h3>
            <p>
               <strong>Content: </strong> {utm.campaignContent || "FALSO"}
            </p>
            <p>
               <strong>Medium: </strong>
               {utm.campaignMedium || "FALSO"}
            </p>
            <p>
               <strong>Name: </strong>
               {utm.campaignName || "FALSO"}
            </p>
            <p>
               <strong>Source: </strong>
               {utm.campaignSource || "FALSO"}
            </p>
            <p>
               <strong>Term: </strong>
               {utm.campaignTerm || "FALSO"}
            </p>
            <p>
               <strong>Etapa do funil: </strong>
               {utm.etapa || "FALSO"}
            </p>

            <div className="button-container">
               <button onClick={(e) => handleDeleteEntry(utm.identificador)}>Deletar</button>
            </div>
         </main>
      </Container>
   )
}

export default UtmCard
