import styled from "styled-components"
import { DefaultContainer } from "../../components/DefaultContainer"

export const Container = styled(DefaultContainer)`
   padding-top: 3rem;

   h1 {
      text-align: center;
      font-weight: 400;
   }

   > section {
      padding-block: 4rem;

      @media (min-width: 45em) {
         padding-block: 5rem;
      }

      @media (min-width: 65) {
         padding-block: 6rem;
      }
   }

   > div {
   }
`

export const ButtonsWrapper = styled.div`
   margin-top: 3rem;

   display: grid;
   gap: 1rem;
   max-width: max-content;
   margin-inline: auto;

   @media (min-width: 45em) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 3rem;
   }
`
