import { useState } from "react"
import { useData } from "../../../../../hooks/useData"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"

const GrupoProjeto = () => {
   const { data } = useData()
   const projectTypes = ["Always On", "Sprint"]

   const [cliente, setCliente] = useState(data.geral[0].clientes[0])
   const [tipo, setTipo] = useState(projectTypes[0])

   return (
      <section id="grupo-projeto">
         <SectionTitle>Grupo de Projeto</SectionTitle>

         <Form>
            <FieldGroup
               as="select"
               label="Cliente *"
               options={data.geral[0].clientes}
               onChange={({ target }) => setCliente(target.value)}
               value={cliente}
            />

            <FieldGroup
               as="select"
               label="Flow/Sprint *"
               value={tipo}
               onChange={(e) => setTipo(e.target.value)}
               options={projectTypes}
            />
         </Form>

         <ResultLabel>{`[${cliente}] | ${tipo}`}</ResultLabel>
      </section>
   )
}

export default GrupoProjeto
