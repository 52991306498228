import { useState, useEffect } from "react"
import { preventSpaceKey } from "../../../helpers/functions"
import { inputValidity } from "../../../helpers/inputValidity"
import { useData } from "../../../../../hooks/useData"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"

const SubgrupoProjeto = () => {
   const { data } = useData()
   const projectTypes = ["Flow", "Sprint"]

   const [cliente, setCliente] = useState(data.geral[0].clientes[0])
   const [tipo, setTipo] = useState(projectTypes[0])
   const [periodo, setPeriodo] = useState("")
   const [entregavel, setEntregavel] = useState("")

   const [isFlowSelected, setIsFlowSelected] = useState(true)

   useEffect(() => {
      tipo === "Flow" ? setIsFlowSelected(true) : setIsFlowSelected(false)
   }, [tipo])

   return (
      <section id="subgrupo-projeto">
         <SectionTitle>Subgrupo de Projeto</SectionTitle>

         <Form>
            <FieldGroup
               as="select"
               label="Cliente *"
               options={data.geral[0].clientes}
               onChange={({ target }) => setCliente(target.value)}
               value={cliente}
            />

            <FieldGroup
               as="select"
               label="Flow/Sprint *"
               value={tipo}
               onChange={(e) => setTipo(e.target.value)}
               options={projectTypes}
            />

            <FieldGroup
               as="input"
               label="Período (mes/mes/mes-ano) *"
               value={periodo}
               onChange={({ target }) => setPeriodo(target.value)}
               onBlur={inputValidity}
               onKeyDown={preventSpaceKey}
            />

            {!isFlowSelected && (
               <FieldGroup
                  as="input"
                  label="Entregável *"
                  onChange={({ target }) => setEntregavel(target.value)}
                  value={entregavel}
                  onBlur={inputValidity}
               />
            )}
         </Form>

         <ResultLabel>{`[${cliente}] | ${tipo} | ${periodo}${
            entregavel && !isFlowSelected ? ` | ${entregavel}` : ""
         }`}</ResultLabel>
      </section>
   )
}

export default SubgrupoProjeto
