import styled from "styled-components"

export const Container = styled.div`
   display: flex;
   align-items: center;
   flex-shrink: 0;
   flex-grow: 1;
   gap: 1rem;

   > div {
      border-radius: 50%;
      overflow: hidden;

      max-width: 2.5rem;
      aspect-ratio: 1;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         object-position: 50% 50%;
      }
   }

   span {
      font-size: 0.9rem;
      font-weight: 600;
   }

   @media (max-width: 70em) {
      display: none;
      visibility: hidden;
      opacity: 0;
   }
`
