import React from "react"
import { Container } from "./style"
import CopyToClipboard from "../CopyToClipboard"

const ResultLabel = ({ children, mt, disabled, ...props }) => {
   return (
      <Container mt={mt}>
         <p {...props}>{children}</p>
         <CopyToClipboard disabled={disabled} copyText={children} />
      </Container>
   )
}

export default ResultLabel
