import { createContext, useEffect, useState } from "react"
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth"
import { useNavigate, useLocation } from "react-router-dom"
import inititializeAuthentication from "../services/firebase/firebase.init"

inititializeAuthentication()

const provider = new GoogleAuthProvider()
provider.setCustomParameters({
   hd: "bowe.co",
})

export const AuthContext = createContext()
AuthContext.displayName = "Authentication Context"

// PROVEDOR DO CONTEXTO
export function AuthContextProvider(props) {
   const navigate = useNavigate()
   const location = useLocation()

   const [authenticated, setAuthenticated] = useState(false)
   const [user, setUser] = useState(null)

   useEffect(() => {
      const sessionToken = localStorage.getItem("@AuthFirebase:token")
      const sessionUser = localStorage.getItem("@AuthFirebase:user")

      if (location == null) {
         return
      }

      if (!sessionUser || !sessionToken) {
         return
      }

      setUser(JSON.parse(sessionUser))
      setAuthenticated(true)

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const redirectQuery = urlParams.get("redirect")

      if (redirectQuery) {
         navigate(`/${redirectQuery}`)
      } else {
         navigate("/")
      }
   }, [])

   // Login function
   const handleLogin = async (param) => {
      if (param === "gerador@bowe.co") {
         setAuthenticated(true)
         localStorage.setItem("@AuthFirebase:token", "acesso-uso-unico")
         localStorage.setItem("@AuthFirebase:user", JSON.stringify("Bowe"))
         navigate("/")
         return
      }

      const auth = getAuth()

      const result = await signInWithPopup(auth, provider)

      if (!result.user) {
         setUser(null)
         setAuthenticated(false)
         return
      }

      setUser(result.user)
      setAuthenticated(true)

      localStorage.setItem("@AuthFirebase:token", result.user.accessToken)
      localStorage.setItem("@AuthFirebase:user", JSON.stringify(result.user))

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const redirectQuery = urlParams.get("redirect")

      if (redirectQuery) {
         navigate(`/${redirectQuery}`)
      } else {
         navigate("/")
      }
   }

   return <AuthContext.Provider value={{ handleLogin, authenticated, user }}>{props.children}</AuthContext.Provider>
}
