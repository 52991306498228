const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028]

const years_reduced = [22, 23, 24, 25, 26, 27, 28]
const years_reduced_drive = ["22", "22/23", "23", "24", "25", "26", "27", "28"]

const currentYear = String(new Date().getFullYear()).slice(2)

const months = [
   "janeiro",
   "fevereiro",
   "marco",
   "abril",
   "maio",
   "junho",
   "julho",
   "agosto",
   "setembro",
   "outubro",
   "novembro",
   "dezembro",
]

const months_reduced = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"]

const versions = ["v1", "v2", "v3", "v4", "v5", "v6", "Vfinal"]

export { years, years_reduced, years_reduced_drive, currentYear, months, months_reduced, versions }
