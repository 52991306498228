import styled, { css } from "styled-components"

export const Container = styled.button`
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.75rem;
   outline: none;
   border: none;
   cursor: pointer;
   text-align: center;

   font-size: 1.125rem;
   background-color: transparent;
   color: ${({ theme }) => theme.white};
   padding: 0.75em;
   border: 2px solid transparent;
   transition: all 150ms ease;
   opacity: 0.7;

   ${(props) =>
      props.active &&
      css`
         opacity: 1;
         border-bottom: 2px solid ${(props) => props.theme.green};
         box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
         filter: brightness(1.2);
         transform: scale(1.05);
      `}
`
