const firebaseConfig = {
    apiKey: "AIzaSyCS4lQC5L5-hZpYqy4RsPjft0lZIiDf8Uk",
    authDomain: "nomenclaturas-bowe.firebaseapp.com",
    projectId: "nomenclaturas-bowe",
    storageBucket: "nomenclaturas-bowe.appspot.com",
    messagingSenderId: "174789489732",
    appId: "1:174789489732:web:3b9de426daa52e65967811",
};

export default firebaseConfig;
