import React from "react"
import { Container } from "./style"
import { useAuth } from "../../hooks/useAuth"

export const PersonDisplayCard = () => {
   const { user } = useAuth()

   if (!user) {
      return null
   }

   return (
      <Container>
         <span>{user.displayName}</span>
         <div>
            <img src={user.photoURL} alt={user.displayName} />
         </div>
      </Container>
   )
}
