import styled, { css } from "styled-components"

export const Container = styled.article`
   word-wrap: break-word;
   word-break: break-all;

   background-color: #ffffff;
   padding: 1rem;
   border-radius: 10px;
   color: #333333;
   border-bottom: 3px solid ${(props) => props.theme.red};

   header {
      > div {
         display: flex;
         align-items: center;
         justify-content: space-between;
         gap: 1rem;
         font-weight: 700;
         margin-bottom: 0.25rem;

         div {
            display: flex;
            gap: 1rem;
            align-items: center;
         }
      }
   }

   main {
      max-height: 0;
      overflow: hidden;
      transition: max-height 150ms linear;

      h3 {
         padding-top: 1rem;
      }
   }

   button {
      outline: none;
      border: none;
      background: none;
      font-size: 2.25rem;

      display: grid;
      place-items: center;
      cursor: pointer;

      transition: all 300ms ease;

      svg {
         color: #b52041;
      }
   }

   .button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
         font-size: 1rem;
         margin-top: 1rem;
         background-color: #b52041;
         font-weight: 500;
         padding: 0.25rem 1rem;
         color: #ffffff;
         border-radius: 10px;
      }
   }

   ${(props) =>
      props.active &&
      css`
         main {
            max-height: ${(props) => (props.listHeight ? `${props.listHeight}px` : null)};
         }

         header button {
            transform: rotate(180deg);
         }
      `}

   .url_param {
      font-weight: 600;
      margin-bottom: 1rem;
   }
`
