import styled from "styled-components"

export const Container = styled.button`
   outline: none;
   border: none;
   background: none;
   font-size: 1.5rem;

   display: grid;
   place-items: center;
   cursor: pointer;

   background: #b52041;
   min-width: 4rem;

   transition: filter 300ms ease;

   > span {
      position: absolute;
      right: 0;
      top: -50px;
      z-index: 4;
      font-weight: 700;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 0.5rem;
      display: block;

      opacity: 0;
      transform: scale(0);
      transform-origin: center bottom;

      &.active {
         opacity: 1;
         transform: scale(1);
      }
   }

   svg {
      fill: #fdfdfd;
   }

   &:hover,
   &:focus {
      filter: brightness(1.2);
   }

   &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      cursor: not-allowed;
   }
`
