import { BrowserRouter } from "react-router-dom"
import { AppRoutes } from "./AppRoutes"
import { AuthContextProvider } from "./contexts/AuthContext"

function App() {
   return (
      <BrowserRouter>
         <AuthContextProvider>
            <AppRoutes />
         </AuthContextProvider>
      </BrowserRouter>
   )
}

export default App
