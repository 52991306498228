function preventSpaceKey(e) {
    const keyPressed = e.code;

    if (keyPressed === "Space") {
        e.preventDefault();
    }
}

function toLowerCaseBlur(value, setState) {
    const lowerCased = value.toLowerCase();
    setState(lowerCased);
}

export { preventSpaceKey, toLowerCaseBlur };
