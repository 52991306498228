import { useState, useEffect } from "react"
import { ValidateErrorsBox } from "../../../../../components/ValidateErrorsBox"
import { useValidateLetters } from "../../../../../hooks/useValidateLetters"
import { toLowerCaseBlur } from "../../../helpers/functions"
import FieldGroup from "../../../../../components/FieldGroup"
import Form from "../../../../../components/Form"
import ObjectiveBox from "../../../../../components/ObjectiveBox"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"

const NomeFormLeadAds = ({ data }) => {
   const dataToDisplay = {
      _bowe: "bw",
      _etapaFunil: data.plataformaAutomacao.etapaFunilPlataformas,
      _nomeProduto: data.geral[0].produtos,
      _tipoFluxo: data.plataformaAutomacao.tipoDeFluxo,
      _tipoMaterial: data.plataformaAutomacao.tipoMaterialRicoAds,
      _canal: data.plataformaAutomacao.canalAds,
   }

   const { errorsList, validateLetters, blockSpecialChars } = useValidateLetters()

   const [bowe, setBowe] = useState(dataToDisplay._bowe)
   const [etapaFunil, setEtapaFunil] = useState(dataToDisplay._etapaFunil[0])
   const [canal, setCanal] = useState(dataToDisplay._canal[0])
   const [nomeProduto, setNomeProduto] = useState("")
   const [tipoMaterialRico, setTipoMaterialRico] = useState(dataToDisplay._tipoMaterial[0])
   const [nomeFormulario, setNomeFormulario] = useState("")

   const [mrFieldActive, setMrFieldActive] = useState(false)

   useEffect(() => {
      if (etapaFunil === "tofu_mr" || etapaFunil === "mofu_mr" || etapaFunil === "bofu_mr") {
         setMrFieldActive(true)
      } else {
         setMrFieldActive(false)
      }
   }, [etapaFunil])

   return (
      <section id="lead-ads-gen">
         <SectionTitle>Nome de form lead ads (FB) e lead gen (LK)</SectionTitle>
         <ObjectiveBox>Padrão para criação de nome de formulário em campanha de Linkedin e Facebook.</ObjectiveBox>

         {errorsList.length > 0 && <ValidateErrorsBox list={errorsList} />}

         <Form>
            <FieldGroup
               as="input"
               label="bw (bowe) *"
               onChange={({ target }) => setBowe(target.value)}
               value={bowe}
               onBlur={(e) => toLowerCaseBlur(bowe, setBowe)}
            />

            <FieldGroup
               as="select"
               label="Etapa do Funil *"
               onChange={({ target }) => setEtapaFunil(target.value)}
               value={etapaFunil}
               options={dataToDisplay._etapaFunil}
            />

            <FieldGroup
               as="select"
               label="Canal *"
               onChange={({ target }) => setCanal(target.value)}
               value={canal}
               options={dataToDisplay._canal}
            />

            <FieldGroup
               as="input"
               label="Produto *"
               onChange={({ target }) => {
                  setNomeProduto(target.value)
               }}
               value={nomeProduto}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => validateLetters(e, setNomeProduto, "Produto *")}
            />

            {mrFieldActive && (
               <FieldGroup
                  as="select"
                  label="Tipo de Material Rico *"
                  onChange={({ target }) => setTipoMaterialRico(target.value)}
                  value={tipoMaterialRico}
                  options={dataToDisplay._tipoMaterial}
               />
            )}

            <FieldGroup
               as="input"
               label="Identificação do Formulário *"
               value={nomeFormulario}
               onChange={({ target }) => setNomeFormulario(target.value)}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => validateLetters(e, setNomeFormulario, "Identificação do Formulário *")}
            />
         </Form>

         <ResultLabel disabled={errorsList.length > 0}>
            {`${bowe}_${etapaFunil}_${canal}_${nomeProduto}${
               tipoMaterialRico && mrFieldActive ? `_${tipoMaterialRico}` : ""
            }${nomeFormulario ? `_${nomeFormulario}` : ""}`}
         </ResultLabel>
      </section>
   )
}

export default NomeFormLeadAds
