import React, { useState } from "react"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import { inputValidity } from "../../../helpers/inputValidity"
import { useData } from "../../../../../hooks/useData"
import { currentYear, years_reduced_drive } from "../../../../../common/data"

import FieldGroup from "../../../../../components/FieldGroup"

const ABM = () => {
   const { data } = useData()

   const dataToDisplay = {
      _cliente: data.geral[0].clientes,
      _flow: data.drive.abm.tipoFlow,
      _periodo: data.geral[0].periodo,
      _tipoMaterial: data.drive.abm.tipoDeMaterial,
      _jornada: data.drive.abm.jornada,
      _nomeMaterial: "",
   }

   const [cliente, setCliente] = useState(dataToDisplay._cliente[0])
   const [flow, setFlow] = useState(dataToDisplay._flow[0])
   const [periodo, setPeriodo] = useState(dataToDisplay._periodo[0])
   const [tipoMaterial, setTipoMaterial] = useState(dataToDisplay._tipoMaterial[0])
   const [ano, setAno] = useState(currentYear)
   const [jornada, setJornada] = useState(dataToDisplay._jornada[0])
   const [nomeMaterial, setNomeMaterial] = useState("")

   return (
      <section id="abm">
         <SectionTitle>ABM</SectionTitle>

         <Form>
            <FieldGroup
               as="select"
               label="Cliente *"
               onChange={({ target }) => setCliente(target.value)}
               value={cliente}
               options={dataToDisplay._cliente}
            />

            <FieldGroup
               as="select"
               label="Tipo do Material *"
               onChange={({ target }) => setTipoMaterial(target.value)}
               value={tipoMaterial}
               options={dataToDisplay._tipoMaterial}
            />

            <FieldGroup
               as="select"
               label="Flow/sprint/headstart ABM/KickOff *"
               onChange={({ target }) => setFlow(target.value)}
               value={tipoMaterial}
               options={dataToDisplay._flow}
            />

            <FieldGroup
               as="select"
               label="Período *"
               onChange={({ target }) => setPeriodo(target.value)}
               value={periodo}
               options={dataToDisplay._periodo}
            />

            <FieldGroup
               as="select"
               label="Ano *"
               onChange={({ target }) => setAno(target.value)}
               value={ano}
               options={years_reduced_drive}
            />

            <FieldGroup
               as="select"
               label="Jornada *"
               onChange={({ target }) => setJornada(target.value)}
               value={jornada}
               options={dataToDisplay._jornada}
            />

            <FieldGroup
               as="input"
               label="Nome do Material *"
               value={nomeMaterial}
               onChange={({ target }) => setNomeMaterial(target.value)}
               onBlur={inputValidity}
            />
         </Form>

         <ResultLabel>
            {`${cliente} - ${tipoMaterial} - ${flow} - ${periodo} - ${ano} - ${jornada} - ${nomeMaterial}`}
         </ResultLabel>
      </section>
   )
}

export default ABM
