import { useState } from "react"
import { inputValidity } from "../../../helpers/inputValidity"
import { preventSpaceKey } from "../../../helpers/functions"
import { versions } from "../../../../../common/data"
import { useData } from "../../../../../hooks/useData"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"

const DA = () => {
   const { data } = useData()

   const dataToDisplay = {
      _cliente: data.geral[0].clientes,
      _tipoMaterial: data.drive.da.tipoDeMaterial,
      _canal: data.drive.da.canal,
      _tamanho: data.drive.da.tamanho,
      _versao: versions,
   }

   const [cliente, setCliente] = useState(dataToDisplay._cliente[0])
   const [tipoMaterial, setTipoMaterial] = useState(dataToDisplay._tipoMaterial[0])
   const [canal, setCanal] = useState("")
   const [tamanho, setTamanho] = useState("")
   const [versao, setVersao] = useState(dataToDisplay._versao[0])
   const [nomeCriativo, setNomeCriativo] = useState("")

   return (
      <section id="da-motion">
         <SectionTitle>DA/Motion</SectionTitle>

         <Form>
            <FieldGroup
               as="select"
               label="Clientes *"
               onChange={({ target }) => setCliente(target.value)}
               value={cliente}
               options={dataToDisplay._cliente}
            />

            <FieldGroup
               as="select"
               label="Tipo de Material *"
               onChange={({ target }) => setTipoMaterial(target.value)}
               value={tipoMaterial}
               options={dataToDisplay._tipoMaterial}
            />

            <FieldGroup
               as="select"
               label="Canal *"
               onChange={({ target }) => setCanal(target.value)}
               value={canal}
               options={dataToDisplay._canal}
            />

            <FieldGroup
               as="select"
               label="Tamanho (Google Display) *"
               onChange={({ target }) => setTamanho(target.value)}
               value={tamanho}
               options={dataToDisplay._tamanho}
               emptyOption="Não se aplica"
            />

            <FieldGroup
               as="select"
               label="Versão *"
               onChange={({ target }) => setVersao(target.value)}
               value={versao}
               options={dataToDisplay._versao}
            />

            <FieldGroup
               as="input"
               label="Nome do Criativo"
               onChange={({ target }) => setNomeCriativo(target.value)}
               value={nomeCriativo}
               onKeyDown={preventSpaceKey}
               onBlur={inputValidity}
            />
         </Form>

         <ResultLabel>
            {`${cliente}-${tipoMaterial}-${canal ? `${canal}-` : ""}${
               tamanho ? `${tamanho}-` : ""
            }${nomeCriativo}-${versao}`}
         </ResultLabel>
      </section>
   )
}

export default DA
