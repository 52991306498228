import { request, GraphQLClient, gql } from "graphql-request"

async function api_fetch_utms() {
   try {
      const { utmCopies } = await request(
         process.env.REACT_APP_API_KEY,
         `
            query UTM {
                utmCopies(orderBy: createdAt_DESC) {
                  id
                  campaignContent
                  campaignMedium
                  campaignName
                  campaignSource
                  campaignTerm
                  url
                  urlParam
                  client
                  date
                  etapa
                  identificador
                }
              }
              
            `
      )

      if (utmCopies) {
         return utmCopies
      } else {
         return
      }
   } catch (err) {
      throw new Error("Something went wrong...")
   }
}

async function api_post(obj) {
   const endPoint = process.env.REACT_APP_API_KEY

   const graphQLClient = new GraphQLClient(endPoint)

   const mutation = gql`
      mutation AdicionaCopyUTM(
         $identificador: String!
         $url: String!
         $campaignSource: String!
         $campaignMedium: String!
         $campaignName: String!
         $campaignTerm: String!
         $campaignContent: String!
         $urlParam: String!
         $client: String!
         $date: String!
         $etapa: String!
      ) {
         createUtmCopy(
            data: {
               identificador: $identificador
               url: $url
               campaignSource: $campaignSource
               campaignMedium: $campaignMedium
               campaignName: $campaignName
               campaignTerm: $campaignTerm
               campaignContent: $campaignContent
               urlParam: $urlParam
               client: $client
               date: $date
               etapa: $etapa
            }
         ) {
            identificador
            url
            campaignSource
            campaignMedium
            campaignName
            campaignTerm
            campaignContent
            urlParam
            client
            date
            etapa
         }

         publishUtmCopy(where: { identificador: $identificador }) {
            identificador
         }
      }
   `

   const variables = {
      identificador: obj.identificador,
      url: obj.url,
      campaignSource: obj.campaignSource,
      campaignMedium: obj.campaignMedium,
      campaignName: obj.campaignName,
      campaignTerm: obj.campaignTerm,
      campaignContent: obj.campaignContent,
      urlParam: obj.urlParam,
      client: obj.client,
      date: obj.date,
      etapa: obj.etapa,
   }

   await graphQLClient.request(mutation, variables)
}

async function api_fetch_clients() {
   try {
      const { padroesGerais } = await request(
         process.env.REACT_APP_API_KEY,
         `
        query FETCH_ALL_DATA{
            padroesGerais{
              geral
            }
          }
            `
      )

      if (padroesGerais) {
         return padroesGerais[0]
      } else {
         return
      }
   } catch (err) {
      throw new Error("Something went wrong...")
   }
}

async function api_delete_utm_copy(id) {
   const endPoint = process.env.REACT_APP_API_KEY

   const graphQLClient = new GraphQLClient(endPoint)

   try {
      const mutation = gql`
         mutation DeleteUTMCopy($identificador: String!) {
            deleteUtmCopy(where: { identificador: $identificador }) {
               identificador
            }
         }
      `

      const variables = {
         identificador: id,
      }

      await graphQLClient.request(mutation, variables)
   } catch (err) {
      console.error(err)
   }
}

export { api_fetch_utms, api_post, api_fetch_clients, api_delete_utm_copy }
